import React, { useState, useEffect } from 'react'
// import { ReUsePtag } from './ReUsePtag'
// import { ReuseImgTag } from './ReuseImgTag'
// import { ReUseHOneTag } from './ReUseHOneTag'
import styled from 'styled-components'
import MainBanner from '../Data/MainBanner.json'
import { SignUpButton } from './SignUpButton'
import { DemoRequestButton } from './DemoRequestButton'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
// import { AppModal } from './AppModal'
import { console, setTimeout } from 'window-or-global'
import { AppModalSlider } from './AppModalSlider'

const AppHomeBannerWapper = styled.div`
  /* max-width: 1920px;
  margin: auto; */
  .MainSection {
    height: 90vh;
    position: relative;
    /* margin-bottom: 300px; */
    padding-top: 15vh;
    @media (max-width: 1450px) {
      /* padding-top: 18vh; */
    }
    @media (max-width: 550px) {
      /* padding-top: 25vh; */
    }
    @media (min-width: 1920px) {
      height: 75vh;
    }
  }
  .HomeBannerImage {
    height: auto;
    /* min-width: 50vw; */
    position: absolute;

    @media (max-width: 1090px) {
      display: none;
    }

    left: 360px;
    top: 80px;
    @media (min-width: 1000px) {
      /* min-width: 180vw;
      left: -600px; */
      height: 80vh;
      margin-left: 449px;
    }

    @media (min-width: 1680px) {
      height: -webkit-fill-available;
      margin-left: 500px;
    }

    @media (min-width: 1920px) {
      /* min-width: 165vw;
      left: -400px; */
      height: 80vh;
      margin-left: 590px;
    }
    @media (min-width: 2560px) {
      /* min-width: 150vw;
      left: -500px; */
      height: auto;
      margin-left: 950px;
    }
  }
  .ParaSection {
    position: absolute;
    padding: 5rem 0rem 20rem 3rem;
    @media (max-width: 2560px) {
      /* padding: 80px 1222px 20px 96px; */
      /* width: 1340px; */
      width: 50vw;
    }
    @media (max-width: 1920px) {
      /* padding: 80px 1222px 20px 96px; */
      /* width: 1340px; */
      width: 45vw;
      padding: 5rem 0rem 20rem 6rem;
    }
    @media (max-width: 1840px) and (min-width: 1650px) {
      /* padding: 50px 1000px 20px 96px; */
      /* width: 1340px; */
      width: 45vw;
      padding: 5rem 0rem 20rem 3rem;
    }
    @media (max-width: 1650px) and (min-width: 1451px) {
      /* padding: 50px 1000px 20px 96px; */
      /* width: 1340px; */
      width: 49vw;
      padding: 5rem 0rem 20rem 3rem;
    }
    @media (max-width: 1590px) and (min-width: 1451px) {
      /* padding: 50px 1000px 20px 96px; */
      /* width: 1340px; */
      width: 52vw;
      padding: 5rem 0rem 20rem 3rem;
    }
    @media (max-width: 1450px) and (min-width: 1091px) {
      /* padding: 0px 790px 20px 96px; */
      /* width: 1340px; */
      width: 57vw;
      padding: 5rem 0rem 20rem 3rem;
    }
    @media (max-width: 1090px) {
      padding: 60px 15px 20px 15px;
      position: unset;
      width: 100vw;
    }
  }
  .twobuttonbar {
    display: flex;
    padding-top: 2rem;

    -webkit-animation: 1.4s ease slideInFromBottom;
    -moz-animation: 1.4s ease slideInFromBottom;
    -o-animation: 1.4s ease slideInFromBottom;
    animation: 1.4s ease slideInFromBottom;

    @keyframes slideInFromBottom {
      from {
        transform: translateY(100px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @media (max-width: 1090px) {
      justify-content: center;
      margin: auto;
    }
    @media (max-width: 650px) {
      flex-wrap: wrap;
    }
  }
  .HomaMainHeading {
    color: #047cc2;
    font-size: 5rem;
    font-weight: bolder;
    max-width: 1750px;
    -webkit-animation: 1s ease slideInFromBottom;
    -moz-animation: 1s ease slideInFromBottom;
    -o-animation: 1s ease slideInFromBottom;
    animation: 1s ease slideInFromBottom;

    @keyframes slideInFromBottom {
      from {
        transform: translateY(100px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @media (max-width: 2100px) {
      font-size: 3rem;
      max-width: 950px;
    }
    @media (max-width: 1300px) {
      max-width: 750px;
    }
    @media (max-width: 1090px) {
      display: flex;
      justify-content: center;
      margin: auto;
      padding: 2rem 2rem 1rem 2rem;
    }
    @media (max-width: 650px) {
      font-size: 2rem;
      padding: 0rem 0rem 1rem 0rem;
    }
  }
  .HomaMainPara {
    padding-top: 2rem;
    padding-right: 262px;
    /* color: rgb(52, 161, 213); */
    color: #000000;
    max-width: 800px;
    line-height: 40px;
    font-size: 1.2rem;
    -webkit-animation: 1.2s ease slideInFromBottom;
    -moz-animation: 1.2s ease slideInFromBottom;
    -o-animation: 1.2s ease slideInFromBottom;
    animation: 1.2s ease slideInFromBottom;

    @keyframes slideInFromBottom {
      from {
        transform: translateY(100px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @media (max-width: 1300px) {
      max-width: 800px;
      padding-top: 1rem;
      line-height: 30px;
    }
    @media (max-width: 1200px) {
      max-width: 550px;
    }
    @media (max-width: 1090px) {
      display: flex;
      justify-content: center;
      margin: auto;
      max-width: 850px;
      padding: 1rem 0rem 3rem 2.5rem;
    }
    @media (max-width: 1250px) and (min-width: 1000px) {
      padding: 1rem 0rem 3rem 5rem;
    }
    @media (max-width: 450px) and (min-width: 375px) {
      padding: 1rem 0rem 3rem 0rem;
    }
  }
  .DownMainPara {
    max-width: 850px;
    padding-top: 0.5rem;
    color: rgb(52, 161, 213);
    @media (max-width: 1920px) {
      max-width: 750px;
    }
    @media (max-width: 1450px) {
      max-width: 650px;
    }
    @media (max-width: 1300px) {
      color: #071741;
      max-width: 550px;
    }
    @media (max-width: 1200px) {
      color: #071741;
      max-width: 450px;
    }
    @media (max-width: 1090px) {
      display: flex;
      justify-content: center;
      margin: auto;
      max-width: 750px;
      padding: 2rem 3rem 2rem 2rem;
      color: rgb(52, 161, 213);
    }
    @media (max-width: 768px) {
      padding: 2rem 0rem 2rem 0rem;
    }
  }
  .SingUPButton {
    margin-right: 10px;
    padding-bottom: 16px;
    @media (max-width: 650px) {
      margin-bottom: 0px;
      margin-top: 0px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .DemoButton {
    margin-left: 10px;
    padding-bottom: 16px;
    @media (max-width: 650px) {
      margin-top: 0px;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .HomeBannerMobileImage {
    display: none;
    @media (max-width: 1090px) {
      display: flex;
      justify-content: center;
      margin: auto;
      height: 60vh;
      /* width: 100%; */
    }
    @media (max-width: 400px) {
      height: 50vh;
    }
  }
  .OnLeave {
    width: 100%;
    height: 100;
    margin: auto;
    outline: none;
    background-color: #fff;
    border: none;
  }
`

export const AppHomeBanner = () => {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setTimeout(() => {
      setShowModal(true)
    }, 100)
  }
  useEffect(() => {
    openModal()
  }, [])

  const signupEvent = () => {
    console.log('track event')
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: 'Special Button',
      // string - required - Type of interaction (e.g. 'play')
      action: 'Click',
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: 'sign up',
    })
  }
  return (
    <AppHomeBannerWapper>
      {MainBanner.map((data, index) => {
        return (
          <div key={'MainBanner_' + index} className="MainSection">
            <div className="ParaSection">
              <h1 className="HomaMainHeading">{data.title}</h1>
              <p className="HomaMainPara">{data.shortPara}</p>
              <img src={data.MobileViewBannerImage} className="HomeBannerMobileImage" />
              {/* <ReUsePtag para={data.downPara} paraStyle='DownMainPara' /> */}
              <div className="twobuttonbar">
                <div className="SingUPButton">
                  <SignUpButton label="Get for free" onClick={signupEvent} />
                </div>
                <div className="DemoButton">
                  <DemoRequestButton label="Talk To Us" />
                </div>
              </div>
            </div>
            <button onLoad={openModal} className="OnLeave">
              <img src={data.ImagePath} className="HomeBannerImage" />
            </button>
            {/* <AppModal showModal={showModal} setShowModal={setShowModal} /> */}
            <AppModalSlider showModal={showModal} setShowModal={setShowModal} />
          </div>
        )
      })}
    </AppHomeBannerWapper>
  )
}
