import React, { useState } from 'react'
import styled from 'styled-components'
import { ReUseHTwotag } from './ReUseHTwoTag'

const LandingPageCarouselCustomersSliderWrapper = styled.div`
  min-height: 40vh;
  display: grid;
  place-items: center;

  .LandingPageCustomersSection {
    @media (max-width: 650px) {
      padding: 0px 0px 0px 15px;
    }
    @media (max-width: 1400px) {
      margin-top: 60px;
    }
    @media (max-width: 1650px) {
      margin-top: 60px;
    }
    @media (min-width: 1920px) {
      margin-top: 230px;
    }
    @media (min-width: 2560px) {
      margin-top: 340px;
    }
    @media (max-width: 1090px) {
      margin-top: 445px;
    }
  }

  .CustomersCustomersSlider {
    height: auto;
    margin: auto;
    position: relative;
    width: 90%;
    display: flex;
    place-items: center;
    overflow: hidden;
  }

  .CustomersCustomersSlide-Track {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* width: calc(250px * 39); */
    /* animation: scroll 40s linear infinite; */
    /* animation: scroll 70s linear infinite; */
    margin-bottom: 130px;
    margin-top: 25px;
  }
  /* .CustomersCustomersSlide-Track:hover {
    animation-play-state: paused;
  } */

  /* @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 34));
    }
  } */

  .CustomersSlide {
    height: 60px;
    width: 154px;
    margin: 4px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    align-items: center;
    padding: 20px;
    perspective: 100px;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    transition: transform 1s;
    object-fit: cover;
  }

  img:hover {
    transform: translateZ(20px);
  }
  a {
    width: 100%;
    transition: transform 1s;
  }

  a:hover {
    transform: translateZ(20px);
  }
  /* .CustomersCustomersSlider::before {
    left: 0;
    top: 0;
  }

  .CustomersCustomersSlider::before {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  } */
`

function AppLandingPageCarousel() {
  const [] = useState(new Date())

  return (
    <LandingPageCarouselCustomersSliderWrapper>
      <div className="LandingPageCustomersSection">
        <ReUseHTwotag Heading="Raindrop is Trusted by Industry Leaders" HeadingStyle="CustomersSectionHeading" />
      </div>
      <div className="CustomersCustomersSlider">
        <div className="CustomersCustomersSlide-Track">
          <div className="CustomersSlide">
            <a href="https://www.jazeeraairways.com/en-in" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/Jazeera_Airways_logo.png"
                alt="JazeeraAirwaysLogo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://cordis.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/Cordis-logo-web.png"
                alt="cordis-logo.png"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://greatcanadian.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/GCELogo.png"
                alt="greatcanadian-Logo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.williams-sonoma.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/WilliamsSonomaBrand.png"
                alt="WilliamSonomaLogo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.potterybarn.com/?cm_type=gnav" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/pottery-barn-logo.png"
                alt="pottery-barn-logo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.westelm.com/?cm_type=gnav&cm_sp=GlobalLinks-_-Topnav-_-WestElmLogo" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/west-elm-logo.png"
                alt="west-elm-logo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.worldmarket.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/World-Market-Logo.png"
                alt="World-Market-Logo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.sephora.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/SephoraLogo.png"
                alt="SephoraLogo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.landsend.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/Lands_end_logo.png"
                alt="Lands_end_logo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.containerstore.com/welcome.htm" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/TheContainerStoreBrand.png"
                alt="TheContainerStoreLogo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.fbbrands.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/FULLBEAUTYBRANDS.png"
                alt="FULLBEAUTYBrandsLogo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.ninjaholdings.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/NinjaHoldings_Logo_Stacked.png"
                alt="NinjaHoldings"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.wwof.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/WorkwearLogo.png"
                alt="WorkwearOutfittersLogo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.cablelabs.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/CableLabsLogo.png"
                alt="CableLabsLogo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://diabetes.org/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/ADA-logo.png"
                alt="ADA-logo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="http://www.diligentetechnologies.com/services" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/diligente-technologies-.png"
                alt="diligente-technologies_logo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://healthy.kaiserpermanente.org/front-door" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/Kaiser_Permanente_logo.png"
                alt="KaiserPermanente-logo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.iotnxt.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/iot-logo.png"
                alt="iotnxt-logo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.drivewealth.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/DriveWealthLogo.png"
                alt="DriveWealthLogo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.turn.bio/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/TurnBiotecchnologiesBrand.png"
                alt="TurnBiotechnologiesLogo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://1111systems.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/eleven_eleven_System.png"
                alt="eleven_eleven_System_ogo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.iress.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/Iress_logo.png"
                alt="iress-logo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.worldpay.com/en" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/worldpay_logo.png"
                alt="worldpay-Logo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.riministreet.com/" target="blank">
              <img
                className="CustomersCustomersSlider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/rimini_street_logo.png"
                alt="RiminiStreetLogo"
              />
            </a>
          </div>
          <div className="CustomersSlide">
            <a href="https://www.synaptics.com/" target="blank">
              <img className="CustomersCustomersSlider-Img" src="img/SynapticsBrand.png" alt="synaptics-Logo" />
            </a>
          </div>
        </div>
      </div>
    </LandingPageCarouselCustomersSliderWrapper>
  )
}
export default AppLandingPageCarousel
